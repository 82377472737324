import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Agenda } from 'src/app/shared/agenda.interface';


@Component({
  selector: 'app-agenda-modal',
  templateUrl: './agenda-modal.page.html',
  styleUrls: ['./agenda-modal.page.scss'],
})
export class AgendaModalPage implements OnInit {

  modalPath: string;
  modalId: number;

  ionicForm: FormGroup;
  isSubmitted = false;
  newAgenda: Agenda;
  loading:any;
  path:string;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    public formBuilder: FormBuilder,
    private firestoreService:FirestoreService,
    public toastController: ToastController,
    public loadingController: LoadingController
  ) { }

  ngOnInit() {
    console.table(this.navParams);
    this.modalId = this.navParams.data.paramID;
    this.modalPath = this.navParams.data.paramPath;
    console.log(this.modalPath);

    this.path = this.modalPath + '/' +this.modalId + '/agenda';

    this.ionicForm = this.formBuilder.group({
      titulo: ['', [Validators.required, Validators.minLength(2)]],
      comentarios: ['', [Validators.required, Validators.minLength(2)]],
    })
  }

  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  submitForm() {
    this.isSubmitted = true;
    if (!this.ionicForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {
      const values = this.ionicForm.value;

      this.newAgenda = {
        id: this.firestoreService.getId(),
        titulo: values.titulo,
        comentarios: values.comentarios,
        fechaCreacion: new Date(),
      }

      //console.log(this.newActividad);
      //this.presentToast();
      this.presentLoading();
      this.firestoreService.createDoc(this.newAgenda,this.path,this.newAgenda.id)
      .then(res => {

        console.log("guardado");
        this.loading.dismiss();
        this.presentToast();
        this.closeModal()
        //this.navCtrl.navigateForward('/actividades-list-details');
  
      }).catch(err => {
  
        console.log("Error = ", err)
  
      })
    }
  }

  get errorControl() {
    return this.ionicForm.controls;
  }

  async presentToast() {
    const toast = await this.toastController.create({
      color: 'success',
      message: 'Punto añadido.',
      duration: 2000
    });
    toast.present();
  }

  async presentLoading() {
      this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Guardando...',
    });
    await this.loading.present();

    //const { role, data } = await loading.onDidDismiss();
    //console.log('Loading dismissed!');
  }

}
