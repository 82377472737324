import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Agenda } from 'src/app/shared/agenda.interface';

@Component({
  selector: 'app-agenda-edit-modal',
  templateUrl: './agenda-edit-modal.page.html',
  styleUrls: ['./agenda-edit-modal.page.scss'],
})
export class AgendaEditModalPage implements OnInit {

  modalPath: string;
  modalId: number;
  agendaId: string;
  path:string;

  ionicForm: FormGroup;
  dataAgenda:Agenda;
  isSubmitted = false;
  loading:any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    public formBuilder: FormBuilder,
    private firestoreService:FirestoreService,
    public toastController: ToastController,
    public loadingController: LoadingController
  ) { }

  ngOnInit() {
    this.modalId = this.navParams.data.paramID;
    this.modalPath = this.navParams.data.paramPath;
    this.agendaId = this.navParams.data.idAgenda;
    console.log(this.modalPath);

    this.path = this.modalPath + '/' +this.modalId + '/agenda';

    this.ionicForm = this.formBuilder.group({
      titulo: ['', [Validators.required, Validators.minLength(2)]],
      comentarios: ['', [Validators.required, Validators.minLength(2)]],
    })
    this.getData();
  }

  getData() {
    this.firestoreService.getDoc(this.path,this.agendaId)
    .subscribe(res =>{
      this.dataAgenda = res as Agenda;
      this.initValuesForm(this.dataAgenda);
      
    })
  }

  initValuesForm(agenda:Agenda):void{
    this.ionicForm.patchValue({
      titulo: agenda.titulo,
      comentarios: agenda.comentarios,
    })
  }

  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  get errorControl() {
    return this.ionicForm.controls;
  }

  submitForm() {
    this.isSubmitted = true;
   if (!this.ionicForm.valid) {
     console.log('Please provide all the required values!')
     return false;
   } else {
     const values = this.ionicForm.value;
     this.presentLoading();
     this.firestoreService.updateDoc(values,this.path, this.agendaId)
     .then(res => {

       console.log("guardado");
       this.loading.dismiss();
       this.presentToast();
       this.closeModal()
       
 
     }).catch(err => {
 
       console.log("Error = ", err)
 
     })
   } 
 }

 async presentLoading() {
  this.loading = await this.loadingController.create({
  cssClass: 'my-custom-class',
  message: 'Guardando...',
  });
  await this.loading.present();

  //const { role, data } = await loading.onDidDismiss();
  //console.log('Loading dismissed!');
  }

  async presentToast() {
    const toast = await this.toastController.create({
      color: 'success',
      message: 'Punto editado.',
      duration: 2000
    });
    toast.present();
  }

}
