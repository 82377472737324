import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirestorageService {

  uploadPercent: Observable<number>;
  
  constructor(private storage: AngularFireStorage) { }

  uploadImageWeb(file:any, path: string, nombre:string):Promise<string> {
    return new Promise(resolve => {
      const realData = file.split(',')[1] 
      var metadata = {
        contentType: 'image/jpeg',
      };
      
      //const file = event.target.files[0];
      const filePath = path + '/' + nombre;
      const ref = this.storage.ref(filePath);
      const task = ref.putString(realData,'base64',metadata);

      this.uploadPercent = task.percentageChanges();
      console.log(this.uploadPercent);
      
      task.snapshotChanges().pipe(
        finalize(() => {
          ref.getDownloadURL()
          .subscribe(res =>{
            const downloadURL = res;
            resolve(downloadURL);
            return;
          })
        } )
     )
    .subscribe();
      
    });
  }

  uploadImageAndroid(file:any, path: string, nombre:string):Promise<string> {
    return new Promise(resolve => {
      var metadata = {
        contentType: 'image/jpeg',
      };
      
      //const file = event.target.files[0];
      const filePath = path + '/' + nombre;
      const ref = this.storage.ref(filePath);
      const task = ref.putString(file,'base64',metadata);

      this.uploadPercent = task.percentageChanges();
      console.log(this.uploadPercent);
      
      task.snapshotChanges().pipe(
        finalize(() => {
          ref.getDownloadURL()
          .subscribe(res =>{
            const downloadURL = res;
            resolve(downloadURL);
            return;
          })
        } )
     )
    .subscribe();
      
    });
  }

  uploadLocalImage(file:any, path: string, nombre:string):Promise<string> {
    return new Promise(resolve => {
      
      const filePath = path + '/' + nombre;
      const ref = this.storage.ref(filePath);
      const task = ref.put(file);

      this.uploadPercent = task.percentageChanges();
      console.log(this.uploadPercent);
      
      task.snapshotChanges().pipe(
        finalize(() => {
          ref.getDownloadURL()
          .subscribe(res =>{
            const downloadURL = res;
            resolve(downloadURL);
            return;
          })
        } )
     )
    .subscribe();
      
    });
  }

  deleteFile(path:string){
    return this.storage.storage.refFromURL(path).delete();
  }

  uploadAudioWeb(file:any, path: string, nombre:string):Promise<string> {
    return new Promise(resolve => {
      const realData = file.split(',')[1] 
      var metadata = {
        contentType: 'audio/wav',
      };
      
      //const file = event.target.files[0];
      const filePath = path + '/' + nombre;
      const ref = this.storage.ref(filePath);
      //const task = ref.putString(file,'base64',metadata);
      const task = ref.putString(realData,'base64',metadata);

      this.uploadPercent = task.percentageChanges();
      console.log(this.uploadPercent);
      
      task.snapshotChanges().pipe(
        finalize(() => {
          ref.getDownloadURL()
          .subscribe(res =>{
            const downloadURL = res;
            resolve(downloadURL);
            return;
          })
        } )
     )
    .subscribe();
      
    });
  }

  uploadAudioAndroid(file:any, path: string, nombre:string):Promise<string> {
    return new Promise(resolve => {
      var metadata = {
        contentType: 'audio/wav',
      };
      
      //const file = event.target.files[0];
      const filePath = path + '/' + nombre;
      const ref = this.storage.ref(filePath);
      const task = ref.putString(file,'base64',metadata);

      this.uploadPercent = task.percentageChanges();
      console.log(this.uploadPercent);
      
      task.snapshotChanges().pipe(
        finalize(() => {
          ref.getDownloadURL()
          .subscribe(res =>{
            const downloadURL = res;
            resolve(downloadURL);
            return;
          })
        } )
     )
    .subscribe();
      
    });
  }

  
}
